<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="search.customer_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for=""> Du <span class="text-danger">*</span> </label>
        <input v-model="search.startDate" type="date" class="form-control" />
      </div>

      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="search.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="search.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="getArchiveReport(search)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Actualiser
        </button>
      </div>
    </div>
    <br />

    <br />
    <div class="bg-white px-2">
      <div class="row border border-secondary fw-bolder py-2 px-0">
        <div class="col-3">Facture</div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">Désignation</div>
            <div class="col-2">Qté</div>
            <div class="col-2">P.U</div>
            <div class="col-2">Total</div>
          </div>
        </div>
        <div class="col-3">Paiements</div>
      </div>
      <div
        v-for="(report, index) in reports"
        :key="index++"
        class="row border align-items-stratch p-0"
        :class="report.totalPriceTTC < 0 ? 'bg-warning-faint' : ''"
      >
        <div
          v-if="report.remark"
          class="col-12 text-center border-bottom border-dark"
        >
          {{ report.remark }}
        </div>
        <div class="col-3 border-end m-0">
          <div class="row">
            <div class="col">Date :</div>
            <div class="col">
              {{ report.date }}
            </div>
          </div>
          <div class="row">
            <div class="col">Total :</div>
            <div class="col">
              {{ report.totalPriceTTC }}
            </div>
          </div>

          <div class="row">
            <div class="col">Remise :</div>
            <div class="col text-success">
              {{ report.discount }}
            </div>
          </div>

          <div class="row">
            <div class="col">Payé :</div>
            <div class="col text-success">
              {{ report.amountPaid }}
            </div>
          </div>
          <div class="row">
            <div class="col">Impayé :</div>
            <div class="col text-danger">
              {{ report.amountUnpaid }}
            </div>
          </div>

          <div class="row">
            <div class="col">Archivé le :</div>
            <div class="col text-danger">
              {{ report.archived_at }}
            </div>
          </div>

          <div class="row d-flex justify-content-center p-2">
            <button
              @click="
                $router.push({
                  name: 'sales-details',
                  params: {
                    reference: report.reference,
                  },
                })
              "
              class="btn btn-primary mx-2"
            >
              Afficher
            </button>
          </div>
        </div>

        <div class="col-6 border-left border-right border-dark m-0">
          <div
            v-for="(sale_item, _index) in report.sale_items"
            :key="_index++"
            class="row py-1"
            :class="_index >= report.sale_items.lenght ? 'border-0' : ''"
          >
            <div class="col-6">
              <span v-if="sale_item.item">
                {{ sale_item.item.fullName }}
              </span>
            </div>

            <div class="col-2">
              {{ sale_item.quantity }}
            </div>
            <div class="col-2">
              {{ sale_item.priceTTC }}
            </div>

            <div class="col-2">
              {{ sale_item.totalPriceTTC }}
            </div>
          </div>
        </div>

        <div class="col-3 border-start m-0">
          <div
            v-for="(payment, _index) in report.payments"
            :key="_index++"
            class="row d-flex"
          >
            <div
              v-if="payments != null"
              class="text-success border border-warning row m-0 px-0"
            >
              <span
                v-if="
                  payments.find((p) => p.reference == payment.payment_reference)
                "
                class="col-6"
              >
                {{
                  payments.find((p) => p.reference == payment.payment_reference)
                    .amount
                }}
              </span>
              <span
                v-if="
                  payments.find((p) => p.reference == payment.payment_reference)
                "
                class="col"
              >
                {{
                  payments.find((p) => p.reference == payment.payment_reference)
                    .reference
                }}
                <br />
                {{
                  payments.find((p) => p.reference == payment.payment_reference)
                    .date
                }}
              </span>
            </div>

            <div class="col-6">+{{ payment.amount }}</div>
            <div class="col">
              <!-- {{ payment.date }} -->
            </div>
          </div>
        </div>
      </div>

      <div class="p-0 m-0">
        <div
          class="row d-flex border-bottom border-secondary border-start border-end"
        >
          <div class="col-2 fw-bold py-1">Reference</div>
          <div class="col fw-bold py-1">Montant</div>

          <div class="col fw-bold py-1">Date</div>
          <div class="col-3 fw-bold py-1">Mode de paiement</div>
          <div class="col-3 fw-bold py-1">Creer par</div>
        </div>
        <div
          v-for="(payment, _index) in payments"
          :key="_index++"
          class="row d-flex border-bottom border-secondary border-start border-end"
        >
          <div class="col-2">
            {{ payment.reference }}
          </div>
          <div class="col text-success text-end">
            +{{ parseFloat(payment.amount).toFixed(2) }}
          </div>

          <div class="col">
            {{ payment.date }}
          </div>
          <div class="col-3">
            {{ payment.paymentMethod }}
            <span v-if="payment.paymentMethod == 'Chèque'">
              {{ payment.checkNumber }}
            </span>
          </div>
          <div v-if="payment.user" class="col-3">
            <span>
              {{ payment.user.name }}
            </span>
          </div>
        </div>
      </div>
      <br />

      Remises :
      <div class="p-0 m-0">
        <div class="row d-flex border border-secondary">
          <div class="col-2 py-2">Référence</div>
          <div class="col py-2">Montant</div>

          <div class="col py-2">Date</div>
          <div class="col-3 py-2">Creer par</div>
        </div>
        <div
          v-for="(discount, _index) in discounts"
          :key="_index++"
          class="row d-flex border-bottom border-secondary border-start border-end"
        >
          <div class="col-2">
            {{ discount.reference }}
          </div>
          <div class="col text-success text-end">
            +{{ parseFloat(discount.amount).toFixed(2) }}
          </div>

          <div class="col">
            {{ discount.date }}
          </div>

          <div v-if="discount.user" class="col-3">
            <span>
              {{ discount.user.name }}
            </span>
          </div>
        </div>
      </div>
      <br />

      Soldes Créditeurs :
      <div class="p-0 m-0">
        <div class="row d-flex border border-secondary">
          <div class="col-2 py-2">Référence</div>
          <div class="col py-2">Montant</div>

          <div class="col py-2">Date</div>
          <div class="col-3 py-2">Créér par</div>
        </div>
        <div
          v-for="(creditBalance, _index) in creditBalances"
          :key="_index++"
          class="row d-flex border-bottom border-secondary border-start border-end"
        >
          <div class="col-2">
            {{ creditBalance.reference }}
          </div>
          <div class="col text-secondary text-end">
            -{{ parseFloat(creditBalance.amount).toFixed(2) }}
          </div>

          <div class="col">
            {{ creditBalance.date }}
          </div>

          <div v-if="creditBalance.user" class="col-3">
            <span>
              {{ creditBalance.user.name }}
            </span>
          </div>
        </div>
      </div>
      <br />

      <div class="row border border-secondary fw-bolder py-2 px-0">
        <div class="col-4"></div>
        <div class="col-4">
          <div class="row">
            <div class="col-6">Montant Total :</div>
            <div class="col-6 bg-warning my-1">
              {{ totalPriceTTC | toFixedWithSpace }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Remise :</div>
            <div class="col-6 bg-success text-white my-1">
              {{ totalDiscount | toFixedWithSpace }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Montant payé :</div>
            <div class="col-6 bg-success text-white my-1">
              {{ totalPaid | toFixedWithSpace }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">Montant impayé :</div>
            <div class="col-6 bg-danger text-white my-1">
              {{ totalUnpaid | toFixedWithSpace }}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="row align-items-center">
            <div class="col-6">Total :</div>
            <div class="col-6 bg-secondary text-white my-1">
              {{ totalReportPayments | toFixedWithSpace }}
            </div>
          </div>

          <div class="row align-items-center border border-danger bg-info">
            <div class="col">Différence :</div>
            <div class="col-6 bg-secondary text-white my-1">
              {{ (totalReportPayments - totalPaid) | toFixedWithSpace }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="reports != ''" class="row justify-content-end">
      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="printGeneralReport(search)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger État Général
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(search)" class="btn btn0 btn-secondary">
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger État Détaillé
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="unarchive(search)" class="btn btn-warning">
          <i class="bi bi-archive"></i>
          Désarchivé
        </button>
      </div>
    </div>

    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      search: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      customer_reference: "",
    };
  },

  beforeMount() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("archive/archiveReportCustomer", this.search);
    // this.$store.dispatch("archive/show", customer_reference);
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("archive", {
      reports: "getReport",
      totalUnpaid: "getTotalUnpaid",
      totalPaid: "getTotalPaid",
      totalPriceTTC: "getTotalPriceTTC",
      totalDiscount: "getTotalDiscount",
      totalReportPayments: "getTotalReportPayments",
      payments: "getReportPayments",
      creditBalances: "getReportCreditBalances",
      discounts: "getReportDiscounts",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("customer/getAll");
      this.search = {};
    },
    async getArchiveReport(data) {
      await this.$store.dispatch("archive/fetchCustomerReport", data);
    },
    async print(data) {
      await this.$store.dispatch("archive/printReportCustomer", data);
    },
    async printGeneralReport(data) {
      await this.$store.dispatch("archive/printGeneralReportCustomer", data);
    },
    async unarchive(data) {
      await this.$confirm({
        message:
          "Souhaitez-vous mettre en archives l'ensemble des factures et paiements liés à ce client ?",
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("archive/destroyCustomerReport", data);
            this.$store.dispatch("archive/fetchCustomerReport", data);
          }
        },
      });
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
